// .video-container{
//     position: absolute;
//     right: 0;
//     bottom: 0;
//     min-width: 100%;
//     min-height: 100%;
//     // transform: translateX(calc((100% - 100vw) / 2));
//     z-index: -1;
// }

.video-style{
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    transform: translateX(calc((100% - 100vw) / 2));
    z-index: -99;
    background: black !important;
}

.play-container{
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    top: 0;
    left: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: black;
    z-index: 98;
    transition: opacity .5s ease-in;
}

.play-logo{
    width: 68px;
}

.play-button{
    margin-top: 35px;
    outline: none !important;
    color: white;
}