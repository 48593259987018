//Bootstrap
@import "~bootstrap/scss/bootstrap";

//Header
@import "./Components/header/header.scss";

//Footer
@import "./Components/footer/footer.scss";

//Background
@import "./Components/background/bg.scss";

//Pages
@import "./Components/home/home.scss";
@import "./Components/quem-somos/quem-somos.scss";
@import "./Components/o-que-fazemos/o-que-fazemos.scss";
@import "./Components/contato/contato.scss";
@import "./Components/contato/agradecimento.scss";

//Progress
@import "./Components/background/progress.scss";

//TESTE
@import "./Components/teste/teste.scss";
@import 'node_modules/react-modal-video/scss/modal-video.scss';


* {
  font-family: 'Red Hat Display', sans-serif;
  
}

html{
  overflow: scroll !important;
  overflow-x: hidden !important;
}

body{
  height: 100%;
  width: 100%;
}

h1 {
  font-size: 55px;
  text-transform: uppercase;
  text-shadow: 0px 3px 10px #000000B3;
}
h2 {
  font-size: 19px;
  text-transform: uppercase;
  text-shadow: 0px 3px 10px #000000B3;
}
h3 {
  font-size: 19px;
  text-transform: uppercase;
  text-shadow: 0px 3px 10px #000000B3;
}
h4 {
  font-size: 15px;
  text-transform: uppercase;
  text-shadow: 0px 3px 10px #000000B3;
}
p {
  font-size: 12px;
  text-transform: uppercase;
  text-shadow: 0px 3px 10px #000000B3;
}

@media (min-width: 992px) and (min-height: 768px) {
  html{
    overflow: hidden !important;
  }
}

@media (min-width: 992px) {
  h1 {
    font-size: 155px;
  }
  h2 {
    font-size: 54px;
  }
  h3 {
    font-size: 43px;
  }
  h4 {
    font-size: 20px;
  }
  p {
    font-size: 15px;
  }
}
