.o-que-fazemos-text {
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    align-items: flex-start !important;
    height: 100%;
    color: white;
    margin-top: 150px;

    h3 {
        font-size: 32px;
    }

    h6 {
        font-size: 19px !important;
    }
}

.o-que-fazemos-swiper {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    width: 100% !important;
}

.o-que-fazemos{
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;

    p {
        margin-top: 25px;
    }

    img {
        width: 100%;
        height: auto;
    }
}

.swiper-button-next:after,
.swiper-button-prev:after{
    color: white;
}

.swiper-pagination-bullet{
    background: white !important;
}

.swiper-pagination-bullet-active{
    background: white !important;
}

@media (max-height: 768px) and (min-width: 992px){
    .o-que-fazemos-container {
        margin-bottom: -100px;
    }
}

@media (min-width: 992px) {
    .o-que-fazemos {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 100vw;
    }

    .o-que-fazemos-row{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .o-que-fazemos-text {
        color: white;
        padding-right: 50px;
        margin-top: 0px !important;
        margin-bottom: 0px !important;


        h3 {
            font-size: 43px;
        }
    }

    .o-que-fazemos-swiper {
        margin-bottom: 0px !important;
    }
}