.splashscreen {
  position: absolute;
  z-index: 99;
  width: 100vw;
  height: 100vh;
  background-color: black;
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column;
  transition: opacity 0.5s ease-in;
  top: 0;
  left: 0;

  .splash-logo {
    width: 68px;
    height: auto;
  }
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

@media (min-width: 992px) {
  .splash-logo {
    width: 175px !important;
    height: auto;
  }

  .lds-ellipsis div {
    width: 13px;
    height: 13px;
  }
}
