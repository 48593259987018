.header {
    background-color: transparent !important;
    // position: absolute;
    // top: 0;
    width: 100%;
    padding: 0 !important;
    z-index: 90;
    height: 100px;

    #basic-navbar-nav{
        .header-opacity{
            background-color: black;
            opacity: 0.5;
            min-height:75vh;
        }
    }

    .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255,1)' stroke-width='3' stroke-linecap='square' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
        width: 27px;
    }

    .header-logo {
        width: 68px;
        height: auto;
        margin-left: 15px;
    }

    button {
        outline: none !important;
        border: none !important;
        margin-left: auto;
        margin-right: 15px;
        padding: 0px;

        &:focus {
            outline: none !important;
            border: none !important;
        }
    }

    .collapsing,
    .show {
        position: fixed;
    }

    .navbar-nav {
        height: 278px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #111417;

        a {
            text-decoration: none;
            color: white;
            font-size: 15px;
            padding-top: 10px !important;
            padding-bottom: 10px !important;
        }

        .nav-active.active{
                color: #FFDB77;
        }

        .header-mobile {
            

            p{
                padding-top: 50px;
                color: white;

                a{
                    font-size: 12px;
                    transition: 0.15s ease-in;

                    &:hover{
                        transition: 0.15s ease-in;
                        color: #FFDB77 !important;
                    }
                }
            }

            .header-mobile-sm {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                a {
                    font-size: 18px;
                    padding-right: 25px;
                    padding-bottom: 0px !important;
                    transition: 0.15s ease-in;

                    &:last-child {
                        padding-right: 0;
                    }

                    &:hover{
                        transition: 0.15s ease-in;
                        color: #FFDB77 !important;
                    }
                }
            }
        }
    }

    .navbar-collapse{
        position: absolute;
        top: 0;
        width: 100%;
    }

    .navbar-collapse.show{
        position: fixed;
    }
}

@media (min-width: 992px) {
    .header {
        background-color: transparent !important;
        position: absolute !important;
        top: 0px;

        #basic-navbar-nav{
            .header-opacity{
                display: none;
            }
        }

        .header-logo {
            width: 88px;
            height: auto;
            margin-left: 50px;
        }

        .header-mobile {
            display: none;
        }

        .collapsing,
        .show {
        background-color: transparent !important;
    }

        .navbar-nav {
            height: 100px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-right: 50px;
            background-color: transparent;

            .nav-link{
                margin-left: 70px;
            }
    
            a{
                text-decoration: none;
                color: white;
                font-size: 20px;
                transition: 0.15s ease-in;
                padding: 0px !important;

                &:hover{
                    transition: 0.15s ease-in;
                    color: #FFDB77;
                }
            }
    
            .header-mobile {
    
                p{
                    padding-top: 0px !important;
                }
            }
        }

        .navbar-collapse{
            position: unset;
            top: 0;
            width: 100%;
        }

        .container-fluid{
            padding: 0px !important;
        }
    }
}
