.agradecimento{
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;

    h4{
        margin-top: 50px;
    }

    .agradecimento-button {
        background-color: transparent;
        color: white;
        font-size: 19px;
        width: 157px !important;
        height: 53px !important;
        border: 2px solid white;
        transition: 0.25s ease-in;
        outline: none;
        margin-top: 50px;

        &:hover {
            background-color: white;
            color: black;
            transition: 0.25s ease-in;
        }
    }
}

@media (min-width: 992px){
    .agradecimento-button {
        font-size: 19px;
        width: 175px !important;
        height: 59px !important;
    }
}