.home{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    width: 100vw;
    height: 100vh;
    margin-top: -100px;
}

@media (min-width: 576px){
    .home-container{
        h1{
            font-size: 70px;
        }

        h2{
            font-size: 28px;
        }
    }
}


@media (min-width: 768px) {
    .home-container{
        h1{
            font-size: 100px;
        }

        h2{
            font-size: 40px;
        }
    }
}

@media (min-width: 992px){
    .home{
        margin-top: 0px;
        height: 100vh;
        width: 100vw;
    }

    .home-container{
        h1{
            font-size: 155px;
        }

        h2{
            font-size: 54px;
        }
    }
}
