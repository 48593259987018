.quem-somos {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    color: white;
    margin-top: 50px;
    width: 100%;
    height: 100%;

    h2 {
        padding-bottom: 50px;
    }

    .eventos-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .eventos {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 20px 0;

            h3 {
                font-weight: bold;
            }

            h4 {
                font-size: 12px !important;
            }
        }
    }

    .quem-somos-video{
        font-size: 35px;
        margin-bottom: 20px;
        padding: 0;
    }
}

.modal-video-brabo {
    z-index: 99 !important;
}

.modal-btn {
    border: none !important;
    position: relative;
    padding: 0px !important;
    margin: 0px 10px !important;
    width: 100% !important;
    height: auto !important;
    background-color: transparent !important;
    text-decoration: none;

    &:focus, :active{
        outline: none !important;
        box-shadow: none !important;
    }

    img {
        padding: 0 !important;
        margin: 0 !important;
        width: 100% !important;
        height: auto !important;
        object-fit: cover !important;
    }

    .play-btn {
        position: absolute;
        left: 50%;
        top: 50%;
        margin-top: -25px;
        margin-left: -25px;
        color: white !important;
        fill: white !important;
        transition: 0.2s ease-in;
    }

    &:hover {
        .play-btn {
            transition: 0.2s ease-out;
            color: rgb(179, 179, 179) !important;
            fill: rgb(179, 179, 179) !important;
        }
    }
}


@media (min-width: 576px) {
    .quem-somos {
        justify-content: center;

        .quem-somos-title{
            font-size: 30px;
        }

        .eventos-container {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            max-width: 500px;
            margin: 0 auto;

            .eventos {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin: 15px 35px;
                width: 150px;
                flex-wrap: wrap;

                h3 {
                    font-size: 22px !important;
                }
            }
        }
    }
}

@media (min-width: 992px) {
    .quem-somos {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 100vw;
        min-height: 768px;
        margin-top: 0px;

        .quem-somos-video{
            font-size: 30px;
            margin-bottom: 20px;
            padding: 0;
        }
        
        .quem-somos-title{
            font-size: 30px;
        }

        .eventos-container {
            max-width: unset;
            flex-wrap: unset;

            .eventos {
                margin: 25px 50px;
                width: unset;
                flex-wrap: unset;

                h3 {
                    font-size: 16px !important;
                    margin-bottom: 0;
                }

                h4 {
                    font-size: 12px !important;
                }
            }
        }

        .modal-btn{
            max-height: 800px !important;
            height: 100% !important;
            img{
                object-fit: cover !important;
                max-height: 800px !important;
            }
        }
    }
}

@media (min-width: 1200px){
    .quem-somos {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 100vw;
        min-height: 768px;
        margin-top: 25px;

        .quem-somos-title{
            font-size: 30px;
        }
        .quem-somos-video{
            font-size: 30px;
        }

        .eventos-container {
            max-width: unset;
            flex-wrap: unset;

            .eventos {
                margin: 25px 50px;
                width: unset;
                flex-wrap: unset;

                h3 {
                    font-size: 20px !important;
                    margin-bottom: 0;
                }

                h4 {
                    font-size: 12px !important;
                }
            }
        }

        .modal-btn{
            img{
                // max-height: 250px;
            }
        }
    }
}

@media (min-width: 1400px){
    .quem-somos {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 100vw;
        min-height: 900px;
        margin-top: 0px;

        .quem-somos-title{
            font-size: 32px;
        }
        .quem-somos-video{
            font-size: 32px;
        }
        
        .eventos-container {
            max-width: unset;
            flex-wrap: unset;

            .eventos {
                margin: 25px 50px;
                width: unset;
                flex-wrap: unset;

                h3 {
                    font-size: 22px !important;
                    margin-bottom: 0;
                }

                h4 {
                    font-size: 13px !important;
                }
            }
        }
        .modal-btn{
            img{
                // max-height: 275px;
            }
        }
    }
}

