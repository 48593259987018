.contato {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: white;
    min-width: 100vw;
    min-height: 100vh;
    margin-top: 150px;
    margin-bottom: 100px;

    .contato-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        // max-width: 600px;
        margin: 0 auto;
    }

    .row {
        width: 100%;
    }

    .contato-infos {
        display: flex;
        flex-direction: column;

        h3 {
            font-size: 35px;
        }

        a {
            text-decoration: none;
            color: white;
            transition: 0.15s ease-in;

            &:hover {
                transition: 0.15s ease-in;
                color:#FFDB77;
            }

            h4 {
                max-width: 248px;
                margin-top: 30px;
            }
        }

        .contato-infos-sm {
            margin-top: 30px;

            a {
                color: white;
                margin-right: 25px;
                transition: 0.15s ease-in;

                &:hover {
                    transition: 0.15s ease-in;
                    color: #FFDB77;
                }
            }
        }
    }

    .contato-inputs {
        display: flex;
        flex-direction: column;
        margin-top: 60px;
        width: 100%;

        input,
        textarea {
            background-color: transparent;
            border: none;
            border-bottom: 2px solid white;
            margin-bottom: 60px;
            padding-bottom: 15px;
            outline: unset;
            width: 100%;
            resize: none;
            color: white !important;


            &::placeholder {
                color: white;
            }

            &:focus {
                &::-webkit-input-placeholder {
                    opacity: 0.5 !important;
                }
            }
        }

        .contato-button-container {
            width: 100%;
            display: flex;
            justify-content: flex-end;

            .contato-button {
                background-color: transparent;
                color: white;
                font-size: 19px;
                width: 157px;
                height: 53px;
                border: 2px solid white;
                transition: 0.25s ease-in;

                &:hover {
                    background-color: white;
                    color: black;
                    transition: 0.25s ease-in;
                }
            }
        }
    }
}

@media (min-width: 992px) {
    .contato {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color: white;
        min-width: 100vw;
        min-height: 100vh;
        margin-top: unset;
        margin-bottom: unset;

        .row {
            width: 100%;
        }

        .contato-container {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100% !important;
            max-width: unset;
        }

        .contato-infos {
            display: flex;
            flex-direction: column;
            margin-right: 130px;

            h3 {
                font-size: 35px;
            }

            h4 {
                max-width: 248px;
                margin-top: 30px;
            }

            .contato-infos-sm {
                margin-top: 30px;

                a {
                    font-size: 22px;
                    color: white;
                    margin-right: 25px;
                }
            }
        }

        .contato-inputs {
            display: flex;
            flex-direction: column;
            margin-top: unset;
            width: 100% !important;
            max-width: 553px !important;

            input,
            textarea {
                background-color: transparent;
                border: none;
                border-bottom: 2px solid white;
                margin-bottom: 60px;
                width: 100% !important;
                max-width: 553px;

                &::placeholder {
                    padding-bottom: 15px;
                }
            }

            .contato-button-container {
                .contato-button {
                    font-size: 19px;
                    width: 175px;
                    height: 59px;
                }
            }
        }
    }
}
