.footer{
    display: none;

    p{
        margin: 0;
    }
}

// @media (max-height: 768px){
//     .footer{
//         display: none !important;
//     }
// }


@media (min-width: 992px) and (min-height: 768px){
    .footer{
        z-index: 90;
        display: flex !important;
        flex-direction: row;
        height: 100px;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0 50px;
        color: white;
        position: absolute;
        bottom: 0;

        a{
            text-decoration: none;
            color: white;

            &:hover{
                transition: 0.15s ease-in;
                color: #FFDB77;
            }
        }

        .footer-sm{
            display: flex;
            flex-direction: row;

            a{
                color: white;
                font-size: 22px;
                margin-left: 30px;

                &:hover{
                    transition: 0.15s ease-in;
                    color:#FFDB77;
                }
            }
        }
    }
}

@media (min-width: 1200px){
    .footer{
        
    }
}